import React, { ReactElement } from "react";
import Image from "next/image";
import styles from "./style.module.scss";
export default function PickRightService(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className="mt-10 lg:my-16">
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <div className="flex flex-col lg:flex-row justify-between">
                    <div className={styles["imgZone"]}>
                        <Image
                            src="/cardprocessingoffers/assets/images/picOpt.jpg"
                            className={styles["backgroundImg"]}
                            alt="creditCardProcessor"
                            layout="responsive"
                            width={472}
                            height={628}
                        />
                    </div>
                    <div className={`${styles["brief"]} w-full lg:w-3/5`}>
                        <h2 className="text-xl  lg:text-[22px] mb-8 font-bold">
                            Choosing the Right Credit Card Processing Service
                            for You
                        </h2>
                        <div className="text-sm lg:text-lg mb-12 font-light">
                            <p className="mb-6 lg:mb-8">
                                Accepting payments is a crucial aspect of
                                running a successful business. However, in
                                today's digital age, accepting payments
                                electronically requires more than just handling
                                physical cash or swiping a card. It involves
                                accepting various payment methods while ensuring
                                the security of your customer's payment data.
                            </p>
                            <p className="mb-6 lg:mb-8">
                                Credit card processing service providers can
                                help with this task. They offer cost-effective
                                ways of accepting customer payments and provide
                                access to funds in a timely manner. With so many
                                options available, choosing the right provider
                                for your business needs is essential. Fill out
                                our easy form, and we will quickly help you find
                                the right credit card processing company for
                                your needs.
                            </p>
                        </div>
                        <button
                            onClick={() => scrollToTop()}
                            style={{ backgroundColor: "#005c58" }}
                            className={` text-white mt-20 py-4 sm:px-11 px-4 text-sm sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
                        >
                            Get My Free Credit Card Processor Quote Now
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
